import { ReactElement, ReactNode, useState } from 'react';
import { Box, styled, Drawer, Typography, Skeleton, Link } from '@mui/material';
import { CabIcon, CabToggleChip } from '@CabComponents';
import colors from '../../colors';
import { PublicLeader } from '../../store';
import { IoTimeOutline, IoPeopleOutline } from 'react-icons/io5';
import { Link as RouterLink } from 'react-router-dom';
import { PAGE_URL } from '../../constants';


const CompanyLogo = styled('img', { label: "CompanyLogo" })<{ src: string }>(({ theme, src }) => ({
  ...(src.trim() === '' ? { width: '100%', height: 74 } : {}),
  maxWidth: '100%',
  maxHeight: 74,
  marginBottom: 16,
  border: `2px solid ${colors.black500}`,
  borderRadius: 6,
  padding: 8,
  backgroundColor: colors.white900
}));

const DrawerContent = styled(Box, { label: "DrawerContent" })(
  {
    padding: '32px 24px 24px 24px',
    backgroundColor: colors.black100,
    maxWidth: '450px',
    width: '20vw',
    minWidth: '300px',
    flex: 1,
  }
);

interface SidePanelProps {
  logo: string;
  name: string | ReactNode;
  description: string | ReactNode;
  durationText: string;
  participantLength?: number;
  leaders?: PublicLeader[];
  showSkeletons?: boolean;
  meetingId?: number;
}

export const SidePanel = ({
  logo, name, description, durationText, participantLength, leaders, showSkeletons, meetingId
}: SidePanelProps): ReactElement => {
  const [open, setOpen] = useState(true);
  const [logoLoaded, setLogoLoaded] = useState(false);
  
  return (
    <>
      <Drawer
        variant="permanent"
        anchor="left"
        open={open}
        onClose={() => setOpen(false)}
      >
        <DrawerContent>
          {showSkeletons ? (
            <Box width='100%' marginBottom={2}>
              <Skeleton height={74} variant='rounded' />
            </Box>
          ) : (
            <CompanyLogo src={logo} alt=''
              {...(logoLoaded ? {} : { width: "100%", height: 74 })}
              onLoad={() => setLogoLoaded(true)}
            />
          )}
          <Box marginTop={1} display="flex" gap={2.5} flexDirection="column">
            <Box gap={1} display="flex" flexWrap="wrap">
              {showSkeletons ? (
                <Skeleton variant='rounded' width={120} height={30} />
              ) : (
                <CabToggleChip
                  title={durationText}
                  label={durationText}
                  icon={<CabIcon Icon={IoTimeOutline} sx={{ fontSize: 20, paddingBottom: '2px' }} />}
                  sx={{backgroundColor: colors.purpleLight, borderColor: colors.black200, height: 30, fontWeight: 500}}
                />
              )}
              {participantLength !== undefined && (
                <CabToggleChip
                  title={`${participantLength} Responded`}
                  label={`${participantLength} Responded`}
                  icon={<CabIcon Icon={IoPeopleOutline} sx={{ fontSize: 20, paddingBottom: '2px' }} />}
                  sx={{backgroundColor: colors.purpleLight, borderColor: colors.black200, height: 30, fontWeight: 500}}
                />
              )}
            </Box>
            {showSkeletons ? (
              <Skeleton width='100%' height={30} variant='rounded' />
            ) : (
              <Typography variant="h1">{name}</Typography>
            )}
            {showSkeletons ? (
              <Box gap={.5} display="flex" flexWrap="wrap">
                <Skeleton variant='rounded' width={120} height={30} />
                <Skeleton variant='rounded' width={120} height={30} />
              </Box>
            ) : (
              <>
                {leaders && (
                  <Box gap={.5} display="flex" flexWrap="wrap">
                    {leaders.map(l => {
                      return <CabToggleChip
                        key={l.id}
                        title={`${l.first_name} ${l.last_name}`}
                        label={`${l.first_name} ${l.last_name}`}
                        sx={{backgroundColor: colors.purpleLight, borderColor: colors.black200, 
                          height: 30, fontWeight: 500}}
                      />;
                    })}
                  </Box>
                )}
              </>
            )}
            <Box>
              <Typography
                component="div"
                variant="body1" 
                whiteSpace='pre-wrap' 
                color={colors.black700}
                sx={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  paddingBottom: 8
                }}>
                {description}
              </Typography>
            </Box>
          </Box>
          {meetingId && (
            <Box 
              display='flex' 
              position='fixed' 
              bottom={0}
              left={0}
              padding={2}
              flexDirection='column' 
              bgcolor={colors.black100}
              width="300px"
            >
              <Typography variant="body2">Are you the meeting owner?</Typography>
              <Box display='flex'>
                <Link component={RouterLink} to={`${PAGE_URL.SCHEDULE}/${meetingId}`} variant="body2" 
                  color={colors.black900} fontWeight={500}
                >
                  Click here
                </Link>
                <Typography variant="body2">&nbsp;to make changes.</Typography>
              </Box>
            </Box>
          )}
        </DrawerContent>
      </Drawer>
    </>
  );
};
